import { FC } from 'react';
import { SvgIcon, SvgIconProps } from '@mui/joy';

export const WalletIcon: FC<SvgIconProps> = (props) => (
  <SvgIcon
    width='24'
    height='24'
    viewBox='0 0 24 24'
    fill='none'
    xmlns='http://www.w3.org/2000/svg'
    {...props}>
    <path
      d='M16.0125 17.55C14.4 17.55 13.125 16.2375 13.125 14.6625C13.125 13.0875 14.4375 11.775 16.0125 11.775H18.825H20.325V11.4C20.325 9.78747 19.0125 8.51247 17.4375 8.51247C15.675 8.51247 13.875 8.51247 12.1125 8.51247C10.9125 8.51247 9.7125 8.51247 8.5125 8.51247C7.9125 8.51247 7.3125 8.54997 6.75 8.47497C5.1375 8.21247 4.575 6.07497 5.8875 5.06247C6.4875 4.57497 7.2375 4.64997 7.9875 4.64997H15.075H17.7C18.2625 4.64997 18.75 4.94997 18.8625 5.54997C18.9375 6.03747 18.8625 6.56247 18.8625 7.04997C18.8625 8.02497 20.3625 8.02497 20.3625 7.04997C20.3625 6.56247 20.4 6.07497 20.3625 5.58747C20.2875 4.49997 19.5375 3.52497 18.4875 3.22497C17.8125 3.03747 16.95 3.11247 16.2375 3.11247C13.65 3.11247 11.0625 3.11247 8.475 3.11247C7.725 3.11247 6.8625 3.03747 6.1125 3.26247C5.2875 3.48747 4.575 4.04997 4.1625 4.79997C3.525 5.88747 3.6375 7.23747 3.6375 8.47497V12.3V16.125V17.925C3.6375 19.5375 4.95 20.8125 6.525 20.8125H17.4375C19.05 20.8125 20.325 19.5 20.325 17.925V17.55H18.825H16.0125Z'
      fill='white'
    />
    <path
      d='M18.8625 13.2749H16.05C15.3 13.2749 14.6625 13.9124 14.6625 14.6624C14.6625 15.4124 15.3 16.0499 16.05 16.0499H18.8625H20.3625V13.2749H18.8625Z'
      fill='white'
    />
    <path
      d='M9.075 5.84985C8.7375 5.84985 8.4375 5.84985 8.1 5.84985C7.125 5.84985 7.125 7.34985 8.1 7.34985H14.8875H15.8625C16.8375 7.34985 16.8375 5.84985 15.8625 5.84985C13.6125 5.84985 11.3625 5.84985 9.075 5.84985Z'
      fill='white'
    />
  </SvgIcon>
);