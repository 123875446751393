import { FC } from 'react';
import { Stack } from '@mui/joy';
import { MintButton } from '@features/mint';
import { WithdrawButton } from '@features/withdraw';
import { ConnectWalletButton } from '@features/connect-wallet';
import { GetReferralLinkButton } from '@features/get-referral-link';
import { Faq } from '@widgets/faq';
import { Hero } from '@widgets/hero';
import { Dashboard } from '@entities/blockchain/ui/user-dashboard.component';
import { WhyUs } from '@widgets/why-us';
import { Roadmap } from '@widgets/roadmap';
import { CallToAction } from '@widgets/call-to-action';
import { HowItWorks } from '@widgets/how-it-works';
import { useBlockchain } from '@entities/blockchain';

export const Home: FC = () => {
  const blockchain = useBlockchain();
  return (
    <Stack direction='column'>
      <Hero
        title={(
          <>
            Get 25 POL <br />
            for each friend
          </>
        )}
        subTitle={(
          <>
            Easy money which works in chain.  <br />
            Get guaranteed bonuses for more friends.<br />
            <br />
            Mint Price: 156.25 POL <br /> With Referral: 62.5 POL (60% Off!)
          </>
        )}
      />
      {blockchain.isWalletConnected ? (
      <Dashboard
        cardsContent={[]}
      />) : (<Stack/>)
      }

      <WhyUs
        cardsContent={[
          {
            title: 'Open Source',
            subTitle: 'The smart contract is open source, secure and transparent.',
            imgSrc: 'assets/images/why-us-1.webp'
          },
          {
            title: 'Your Earnings — Your Control',
            subTitle: "If you bring in friends, only you can take out the money you've made from it.",
            imgSrc: 'assets/images/why-us-2.webp'
          },
          {
            title: 'Designed for Longevity',
            subTitle: 'We made this with the future in mind, planning for it to grow and be around for a long time.',
            imgSrc: 'assets/images/why-us-3.webp'
          },
          {
            title: 'Safe and Sound',
            subTitle: 'We use top-notch safety measures to keep everything running smoothly and securely.',
            imgSrc: 'assets/images/why-us-4.webp'
          },
        ]}
      />
      <HowItWorks
        cardsContent={[
          {
            title: 'Setup and connect your wallet',
            subTitle: 'Use Metamask to connect to the app.',
            slotAction: <ConnectWalletButton sizeVariant='big' />
          },
          {
            title: 'Mint your token',
            subTitle: 'Each wallet is limited to minting one token only. Minting through a referral link gives a significant discount.',
            slotAction: <MintButton content='Mint token' />
          },
          {
            title: 'Invite friends and get 25 POL for each',
            subTitle: 'Earn rewards by inviting friends and when they invite others. Click the button below to generate your personal referral link.',
            slotAction: <GetReferralLinkButton />
          },
          {
            title: 'Withdraw money',
            subTitle: 'Click the button below to withdraw your earned bonuses directly to your wallet.',
            slotAction: <WithdrawButton />
          },
        ]}
      />
      <Roadmap />
      <Faq
        cardsContent={[
          {
            summary: 'What is Money Chain?',
            details: 'Money Chain is an innovative cryptocurrency project designed to make earning in the crypto world easy and rewarding through our unique referral system. By inviting friends to join, you can earn crypto rewards and bonuses.'
          },
          {
            summary: 'How does Money Chain work?',
            details: 'Money Chain operates on a secure, open-source smart contract. When you invite friends using your referral link, you earn 25 POL for each friend who joins. Additionally, you earn bonuses as your friends invite others, creating a chain of earnings.'
          },
          {
            summary: 'Why should I trust Money Chain?',
            details: 'Our smart contract is transparent, secure, and open-source. We prioritize security and have implemented top-notch safety measures. Our goal is to create a sustainable and trustworthy platform for long-term growth.'
          },
          {
            summary: 'How much can I earn by inviting friends?',
            details: 'You earn 25 POL for each friend who joins through your referral link. Additionally, you earn bonuses as your invited friends bring in more users. For example, if you invite 10 friends, you will earn 250 POL etc.'
          },
          {
            summary: 'How are bonuses calculated in the referral chain?',
            details: 'The referral bonuses work as follows: You earn 25 POL for each direct referral. If your direct referral invites someone, you earn 10 POL. If that second-level referral invites someone, you earn 5 POL, and so on, following a decreasing percentage.'
          },
          {
            summary: 'Can I see the code behind Money Chain?',
            details: 'Yes, our smart contract is open-source and available for review.'
          },
          {
            summary: 'What do I need to get started?',
            details: 'To get started with Money Chain, you need: 1) Metamask wallet. 2) Some POL for transaction fees. 3) To follow the steps to mint your token and get your referral link.'
          },
          {
            summary: 'How do I mint my token?',
            details: 'Minting your token is simple. Once you have set up your wallet, connect it to our platform, and follow the on-screen instructions to mint your token.'
          },
          {
            summary: 'How can I get support or stay updated with the latest news?',
            details: 'Join our Telegram Group and Discord for live support and community discussions'
          },
        ]}
      />
      <CallToAction />
    </Stack>
  );
};