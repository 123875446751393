import { Box, styled } from '@mui/joy';

export const BlurryBackground = styled(Box)(({ theme }) => ({
  position: 'absolute',
  top: 60,
  right: 0,
  left: '50%',
  bottom: 0,
  zIndex: 1,
  opacity: 1.0,
  width: '100%',
  maxWidth: 1300,
  height: 357,
  transform: 'translate(-50%)',
  borderRadius: '50%',
  background: `linear-gradient(0deg, ${theme.palette.common[700]}, ${theme.palette.common[500]})`,
  filter: 'blur(45px)'
}));