import { Box, styled } from '@mui/joy';

export const BlurryBackground = styled(Box)(({ theme }) => ({
  zIndex: 16,
  position: 'absolute',
  top: -70,
  right: 0,
  left: 0,
  bottom: 0,
  opacity: 1.0,
  width: 713,
  height: 458,
  transform: 'translate(-89%)',
  borderRadius: '50%',
  filter: 'blur(70px)',
  background: `linear-gradient(0deg, ${theme.palette.common[700]}, ${theme.palette.common[500]})`,
  [theme.breakpoints.down(1491)]: { display: 'none' }
}));