import { createBrowserRouter } from 'react-router-dom';
import { Home } from '@pages/home';
import { NotFound } from '@pages/not-found';
import { Layout } from '@widgets/layout';

export const appRouter = createBrowserRouter([
  {
    element: <Layout />,
    errorElement: <NotFound />,
    children: [
      {
        path: '/',
        element: <Home />
      },
    ],
  },
]);