import { FC } from 'react';
import { Outlet } from 'react-router-dom';
import { Stack } from '@mui/joy';
import { Header } from './header.component';
import { Footer } from './footer.component';
import { BlurryBackground } from './blurry-background.component';

export const Layout: FC = () => {
  return (
    <Stack direction='column'
      sx={({ palette }) => ({
        width: '100%',
        height: '100%',
        position: 'relative',
        background: palette.common[100]
      })}>
      <Header />
      <Outlet />
      <Footer />
      <BlurryBackground />
    </Stack>
  );
};