import { FC, SyntheticEvent } from 'react';
import
JoySnackbar,
{
  SnackbarProps as JoySnackbarProps,
  SnackbarCloseReason
} from '@mui/joy/Snackbar';
import { IconButton } from '@mui/joy';
import { Close } from '@mui/icons-material';
import { SnackColor } from '../lib';
import { SnackIcon } from './snack-icon';

export type SnackbarProps = JoySnackbarProps & {
  message: string;
  onClose: () => void;
  color: SnackColor;
}

export const Snackbar: FC<SnackbarProps> = ({ color, message, onClose, ...props }) => {

  const handleClose = (_: Event | SyntheticEvent<any, Event> | null, reason: SnackbarCloseReason): void => {
    if (reason === 'clickaway') return;
    onClose();
  };

  return (
    <JoySnackbar
      size='lg'
      anchorOrigin={{
        horizontal: 'center',
        vertical: 'bottom'
      }}
      color={color}
      onClose={handleClose}
      startDecorator={(
        <SnackIcon type={color} />
      )}
      endDecorator={(
        <IconButton
          variant='plain'
          onClick={onClose}
          sx={{
            ml: 'auto',
            color: 'inherit',
            '--Icon-color': 'unset',
            '&:hover': {
              backgroundColor: 'transparent',
              color: 'inherit',
            }
          }}>
          <Close sx={{ height: 18, width: 18 }} />
        </IconButton>
      )}
      {...props}>
      {message}
    </JoySnackbar>
  );
};
