import { FC } from 'react';
import {
  Stack,
  StackProps,
  AccordionGroup,
  Typography
} from '@mui/joy';
import { FaqCard } from './faq-card.component';

export type FaqProps = StackProps & {
  cardsContent: Array<{
    summary: string;
    details: string;
  }>;
};

export const Faq: FC<FaqProps> = ({
  cardsContent,
  sx,
  ...props
}) => (
  <Stack
    id='faq'
    component='div'
    direction='column'
    alignItems='center'
    justifyContent='center'
    sx={[({ breakpoints, palette }) => ({
      background: palette.common[100],
      rowGap: 9,
      paddingInline: 2,
      paddingBlock: 13,
      [breakpoints.down(768)]: {
        paddingBlock: 9,
        rowGap: 5
      }
    }),
    ...(Array.isArray(sx) ? sx : [sx])
    ]}
    {...props}>
    <Typography
      sx={({ fontSize, palette, breakpoints }) => ({
        fontFamily: 'Michroma',
        fontSize: fontSize.xl3,
        lineHeight: '56.88px',
        color: palette.common.white,
        fontWeight: 400,
        textAlign: 'center',
        [breakpoints.down(1024)]: {
          fontSize: fontSize.xl,
          lineHeight: '34.13px'
        }
      })}>
      Your questions, answered
    </Typography>
    <AccordionGroup
      sx={({ breakpoints }) => ({
        width: '100%',
        maxWidth: 1200,
        rowGap: 2,
        [breakpoints.down(768)]: {
          rowGap: 1
        },
      })}>
      {cardsContent.map(({ ...props }, index) => (
        <FaqCard
          key={index}
          {...props}
        />
      ))}
    </AccordionGroup>
  </Stack>
);