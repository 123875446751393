import { Box } from '@mui/joy';
import { FC } from 'react';

export const WhyUsStatsSeparator: FC = () => (
  <Box
    sx={({ palette, breakpoints }) => ({
      height: 8,
      width: 8,
      borderRadius: 90,
      borderStyle: 'solid',
      borderWidth: 2,
      borderColor: palette.common[600],
      [breakpoints.down(1198)]: {
        display: 'none'
      }
    })}
  />
);