import { FC } from 'react';
import { Box, Stack, Typography } from '@mui/joy';
import { StageCard } from './stage-card.component';

export const Roadmap: FC = () => (
  <Stack
    id='roadmap'
    component='div'
    direction='column'
    alignItems='center'
    justifyContent='center'
    sx={({ breakpoints, palette }) => ({
      position: 'relative',
      background: palette.common[100],
      paddingInline: 2,
      paddingBlock: 13,
      rowGap: 12.75,
      [breakpoints.down(769)]: {
        paddingBlockStart: 9,
        paddingBlockEnd: 12.875
      },
      [breakpoints.down('sm')]: {
        paddingBlockStart: 9, // Reduce padding for small screens
        rowGap: 30, // Reduce the row gap to avoid overlap on mobile
      }
    })}>
    <Typography
      sx={({ fontSize, palette, breakpoints }) => ({
        fontFamily: 'Michroma',
        fontSize: fontSize.xl3,
        lineHeight: '36.88px',
        color: palette.common.white,
        fontWeight: 400,
        textAlign: 'center',
        [breakpoints.down(1024)]: {
          fontSize: fontSize.xl,
          lineHeight: '10px'
        },
        [breakpoints.down('sm')]: {
          marginBottom: 10, // Add more space between heading and content for mobile
        }
      })}>
      The Roadmap
    </Typography>
    <Box
      component='img'
      src='assets/images/chain.webp'
      sx={({ breakpoints }) => ({
        position: 'absolute',
        top: 233,
        left: 'auto',
        scale: '1.1',
        [breakpoints.down(541)]: {
          top: 127,
          left: -90
        },
        [breakpoints.down(489)]: {
          left: -113
        },
        [breakpoints.down(455)]: {
          left: -127
        },
        [breakpoints.down(439)]: {
          left: -137
        },
        [breakpoints.down(414)]: {
          left: -182
        },
        [breakpoints.down(390)]: {
          left: -196,
        }
      })}
    />
    <Stack
      sx={({ breakpoints }) => ({
        display: 'grid',
        gridTemplateColumns: '1fr 323px 1fr',
        width: '100%',
        maxWidth: 929,
        height: 843,
        rowGap: 5.375,
        [breakpoints.down(768)]: {
          rowGap: 1
        },
        [breakpoints.down(543)]: {
          display: 'flex',
          alignItems: 'flex-end',
          justifyContent: 'flex-end',
          rowGap: 8,
        }
      })}>
      <StageCard
        quarter='4'
        period='2024'
        description='Deploy the foundational smart contract for Money Chain, allowing users to mint and interact with tokens.'
        sx={({ breakpoints }) => ({
          gridColumnStart: 3,
          gridColumnEnd: 4,
          gridRowStart: 1,
          gridRowEnd: 2,
          maxWidth: 323,
          boxSizing: 'border-box',
          [breakpoints.down(768)]: {
            maxWidth: 222
          }
        })}
      />
      <StageCard
        quarter='1'
        period='2025'
        description='Introduce user-friendly interfaces and additional functionalities to increase user engagement and activity.'
        sx={({ breakpoints }) => ({
          gridColumnStart: 1,
          gridColumnEnd: 2,
          gridRowStart: 2,
          gridRowEnd: 3,
          maxWidth: 323,
          [breakpoints.down(768)]: {
            maxWidth: 222
          }
        })}
      />
      <StageCard
        quarter='2'
        period='2025'
        description='Collaborate with the community to design and launch a new token, focusing on governance or additional utilities within the Money Chain ecosystem.'
        sx={({ breakpoints }) => ({
          gridColumnStart: 3,
          gridColumnEnd: 4,
          gridRowStart: 3,
          gridRowEnd: 4,
          maxWidth: 323,
          [breakpoints.down(768)]: {
            maxWidth: 222
          }
        })}
      />
      <StageCard
        quarter='3'
        period='2025'
        description='Expand the token utility beyond the initial scope, possibly integrating with other services or games.'
        sx={({ breakpoints }) => ({
          gridColumnStart: 1,
          gridColumnEnd: 2,
          gridRowStart: 4,
          gridRowEnd: 5,
          maxWidth: 323,
          [breakpoints.down(768)]: {
            maxWidth: 222
          }
        })}
      />
      <StageCard
        quarter='4'
        period='2025'
        description='List the newly created token on major exchanges and distribute airdrops to all active members of Money Chain as a reward for early adoption and engagement.'
        sx={({ breakpoints }) => ({
          gridColumnStart: 3,
          gridColumnEnd: 4,
          gridRowStart: 5,
          gridRowEnd: 6,
          maxWidth: 323,
          [breakpoints.down(768)]: {
            maxWidth: 222
          }
        })}
      />
    </Stack>
  </Stack>
);