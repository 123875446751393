export type NavigationItem = {
  text: string;
  slug: string;
};

export const navigationData: Array<NavigationItem> = [
  { text: 'Why us', slug: '#why-us' },
  { text: 'How it works', slug: '#how-it-works' },
  { text: 'Roadmap', slug: '#roadmap' },
  { text: 'FAQ', slug: '#faq' },
  { text: 'DOCs', slug: 'https://money-chain.gitbook.io/money-chain' },
];