import { FC, ReactNode } from 'react';
import { Stack, StackProps, Typography } from '@mui/joy';
import { OrderProcessStep } from './order-process-step.component';

export type HowItWorksCardProps = StackProps & {
  step: number,
  title: string;
  subTitle: string;
  slotAction: ReactNode;
};

export const HowItWorksCard: FC<HowItWorksCardProps> = ({
  step,
  title,
  subTitle,
  slotAction,
  sx,
  ...props
}) => (
  <Stack
    direction='column'
    alignItems='flex-start'
    justifyContent='center'
    sx={[
      ({ breakpoints }) => ({
        rowGap: 5,
        width: '100%',
        maxWidth: 400,
        minHeight: 196,
        [breakpoints.down(897)]: {
          maxWidth: 222
        },
        [breakpoints.down(815)]: {
          maxWidth: '100%'
        },
      }),
      ...(Array.isArray(sx) ? sx : [sx])
    ]}
    {...props}>
    <OrderProcessStep
      sx={({ breakpoints }) => ({
        [breakpoints.up(769)]: {
          display: 'none',
        }
      })}>
      {step}
    </OrderProcessStep>
    <Stack
      direction='column'
      alignItems='flex-start'
      justifyContent='center'
      rowGap={2}>
      <Typography
        sx={({ palette, breakpoints, fontSize }) => ({
          fontFamily: 'Michroma',
          fontSize: fontSize.xl,
          fontWeight: 400,
          lineHeight: '34.13px',
          textAlign: 'left',
          color: palette.common.white,
          [breakpoints.down(897)]: {
            fontSize: fontSize.md,
            lineHeight: '22.75px',
          },
        })}>
        {title}
      </Typography>
      <Typography
        sx={({ palette, breakpoints, fontSize }) => ({
          fontFamily: 'Michroma',
          fontSize: fontSize.md,
          fontWeight: 200,
          lineHeight: '24px',
          textAlign: 'left',
          color: palette.common[400],
          [breakpoints.down(897)]: {
            fontSize: 14,
            lineHeight: '21px',
          },
        })}>
        {subTitle}
      </Typography>
    </Stack>
    {slotAction}
  </Stack>
);