import { FC } from 'react';
import {
  Stack,
  StackProps,
  Typography
} from '@mui/joy';

export type StageCardProps = StackProps & {
  quarter: string;
  period: string;
  description: string;
};

export const StageCard: FC<StageCardProps> = ({
  quarter,
  period,
  description,
  sx,
  ...props
}) => (
  <Stack
    direction='column'
    alignItems='flex-start'
    justifyContent='center'
    rowGap={2}
    sx={[...(Array.isArray(sx) ? sx : [sx])]}
    {...props}>
    <Stack
      direction='row'
      alignItems='flex-start'
      justifyContent='center'
      sx={[
        ({ breakpoints }) => ({
          columnGap: 2,
          [breakpoints.down(768)]: { columnGap: 1 }
        })
      ]}>
      <Typography
        sx={({ breakpoints, palette, fontSize }) => ({
          fontFamily: 'Michroma',
          fontSize: fontSize.xl,
          fontWeight: 400,
          lineHeight: '34.13px',
          textAlign: 'left',
          color: palette.common[700],
          [breakpoints.down(768)]: { fontSize: fontSize.md }
        })}>
        {`Q${quarter}`}
      </Typography>
      <Typography
        sx={({ breakpoints, palette, fontSize }) => ({
          fontFamily: 'Michroma',
          fontSize: fontSize.xl,
          fontWeight: 400,
          lineHeight: '34.13px',
          textAlign: 'left',
          color: palette.common.white,
          [breakpoints.down(768)]: { fontSize: fontSize.md }
        })}>
        {period}
      </Typography>
    </Stack>
    <Typography
      sx={({ breakpoints, palette, fontSize }) => ({
        fontFamily: 'Poppins',
        fontSize: fontSize.md,
        fontWeight: 300,
        lineHeight: '24px',
        textAlign: 'left',
        color: palette.common[400],
        [breakpoints.down(768)]: { fontSize: 14 }
      })}>
      {description}
    </Typography>
  </Stack >
);