import { extendTheme } from '@mui/joy';

interface CommonPalette {
  50: string;
  100: string;
  200: string;
  300: string;
  400: string;
  500: string;
  600: string;
  700: string;
  white: string;
  black: string;
};

declare module '@mui/joy/styles' {
  interface Palette {
    common: CommonPalette;
  }
  interface PaletteOptions {
    common: CommonPalette;
  }
};

declare module '@mui/joy/Button' {
  interface ButtonPropsColorOverrides {
    primary: true;
    secondary: true;
    third: true;
  }
};

declare module '@mui/joy/Link' {
  interface LinkPropsColorOverrides {
    primary: true;
  }
}

export const joyTheme = extendTheme({
  cssVarPrefix: 'joy',
  colorSchemes: {
    dark: {
      palette: {
        common: {
          50: '#0B0B0B',
          100: '#141414',
          200: '#222222',
          300: '#4C4C4C',
          400: '#BBBBBB',
          500: '#284027',
          600: '#498051',
          700: '#52A051',
          white: '#FFFFFF',
          black: '#000000',
        },
        text: {
          icon: 'var(--joy-palette-custom-300)',
          primary: 'var(--joy-palette-custom-500)',
        },
        background: {
          body: 'var(--joy-palette-custom-100)',
        },
        divider: 'var(--joy-palette-custom-600)',
      },
    },
  },
  fontFamily: {
    body: 'Poppins',
  },
  fontSize: {
    sm: '1rem',
    md: '2rem',
    lg: '2.25rem',
    xl: '3rem',
    xl2: '4rem',
    xl3: '5rem',
    xl4: '8rem',
  },
  zIndex: {
    popup: 1000,
    modal: 1300,
    snackbar: 1400,
    tooltip: 1500,
  },
  components: {
    JoyButton: {
      styleOverrides: {
        root: ({ ownerState, theme }) => ({
          borderRadius: 16,
          fontSize: theme.fontSize.md,
          fontWeight: 500,
          lineHeight: '24px',
          /**
           * Variants & Colors
           * */
          ...(ownerState.color === 'primary' && {
            borderWidth: 1,
            borderStyle: 'solid',
            borderColor: theme.colorSchemes.dark.palette.common.white,
            backgroundColor: theme.colorSchemes.dark.palette.common.white,
            color: theme.colorSchemes.dark.palette.common[700],
            ':hover': {
              borderColor: theme.colorSchemes.dark.palette.common[700],
              backgroundColor: theme.colorSchemes.dark.palette.common[700],
              color: theme.colorSchemes.dark.palette.common.white,
            },
            ':active': {
              borderColor: theme.colorSchemes.dark.palette.common[700],
              backgroundColor: theme.colorSchemes.dark.palette.common.white,
              color: theme.colorSchemes.dark.palette.common[700],
            },
          }),

          ...(ownerState.color === 'secondary' && {
            borderWidth: 1,
            borderStyle: 'solid',
            borderColor: theme.colorSchemes.dark.palette.common[700],
            backgroundColor: theme.colorSchemes.dark.palette.common[700],
            color: theme.colorSchemes.dark.palette.common.white,
            ':hover': {
              borderColor: theme.colorSchemes.dark.palette.common.white,
              backgroundColor: theme.colorSchemes.dark.palette.common.white,
              color: theme.colorSchemes.dark.palette.common[700],
            },
            ':active': {
              borderColor: theme.colorSchemes.dark.palette.common.white,
              backgroundColor: theme.colorSchemes.dark.palette.common[700],
              color: theme.colorSchemes.dark.palette.common.white,
            },
          }),

          ...(ownerState.color === 'third' && {
            borderWidth: 1,
            borderStyle: 'solid',
            borderColor: theme.colorSchemes.dark.palette.common[700],
            backgroundColor: theme.colorSchemes.dark.palette.common[100],
            color: theme.colorSchemes.dark.palette.common[700],
            ':hover': {
              borderColor: theme.colorSchemes.dark.palette.common[700],
              backgroundColor: theme.colorSchemes.dark.palette.common[700],
              color: theme.colorSchemes.dark.palette.common.white,
            },
            ':active': {
              borderColor: theme.colorSchemes.dark.palette.common.white,
              backgroundColor: theme.colorSchemes.dark.palette.common[100],
              color: theme.colorSchemes.dark.palette.common[700],
            },
          })
        })
      }
    },
    JoyLink: {
      styleOverrides: {
        root: (({ ownerState, theme }) => ({
          ...(ownerState.color === 'primary' && {
            fontWeight: 300,
            lineHeight: '24px',
            textAlign: 'left',
            color: theme.colorSchemes.dark.palette.common.white,
            textDecorationColor: theme.colorSchemes.dark.palette.common.white,
            ':hover': {
              color: theme.colorSchemes.dark.palette.common[700],
              textDecorationColor: theme.colorSchemes.dark.palette.common[700],
            },
            ':active': {
              color: theme.colorSchemes.dark.palette.common.white,
              textDecorationColor: theme.colorSchemes.dark.palette.common.white,
            },
            '&.Mui-disabled': {
              // not set
            },
          }),
        }))
      }
    },
    JoySnackbar: {
      styleOverrides: {
        root: () => ({
          zIndex: 9999,
        })
      }
    }
  }
});