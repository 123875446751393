import { FC } from 'react';
import { Box } from '@mui/joy';
import { Breakpoints } from '@mui/system';

const getBreakpointStyles = (breakpoints: Breakpoints) => ({
  [breakpoints.down(1672)]: {
    width: 870,
    top: 37,
  },
  [breakpoints.down(1593)]: { width: 850 },
  [breakpoints.down(1493)]: { width: 765 },
  [breakpoints.down(1407)]: { top: 299 },
  [breakpoints.down(1163)]: { top: 367 },
  [breakpoints.down(935)]: {
    top: 397,
    width: 735,
  },
  [breakpoints.down(777)]: {
    top: 419,
    width: 705,
  },
  [breakpoints.down(768)]: { top: 273 },
  [breakpoints.down(665)]: { width: 690 },
  [breakpoints.down(645)]: {
    width: 659,
    top: 282,
  },
  [breakpoints.down(613)]: {
    width: 598,
    top: 292,
  },
  [breakpoints.down(568)]: { width: 569 },
  [breakpoints.down(533)]: { width: 497 },
  [breakpoints.down(470)]: { width: 454 },
  [breakpoints.down(380)]: { width: 377 },
});

export const HeroImg: FC = () => (
  <Box
    component='img'
    src='assets/images/hero.webp'
    sx={({ breakpoints }) => ({
      position: 'absolute',
      zIndex: 4,
      right: 0,
      top: 23,
      ...getBreakpointStyles(breakpoints),
    })}
  />
);