import { FC } from 'react';
import { Stack } from '@mui/joy';
import {
  Logo,
  Navbar,
  Copyright,
  SocialLinks
} from '@shared/ui';
import {
  navigationData,
  socialLinkData
} from '@shared/config';

export const Footer: FC = () => {
  return (
    <Stack
      direction='column'
      justifyContent='center'
      sx={({ palette }) => ({
        rowGap: 8,
        paddingBlock: 6,
        paddingInline: 2,
        background: palette.common[50],
      })}>
      <Stack
        direction='row'
        alignItems='center'
        justifyContent='flex-start'
        sx={({ breakpoints }) => ({
          columnGap: 7,
          width: '100%',
          maxWidth: 1200,
          marginInline: 'auto',
          [breakpoints.down(768)]: {
            flexDirection: 'column',
            alignItems: 'flex-start',
            rowGap: 3
          }
        })}>
        <Stack
          direction='row'
          alignItems='center'
          justifyContent='flex-start'
          sx={({ breakpoints }) => ({
            columnGap: 7,
            width: '100%',
            [breakpoints.down(768)]: {
              flexDirection: 'column',
              alignItems: 'flex-start',
              rowGap: 4
            }
          })}>
          <Logo />
          <Navbar
            bolder
            items={navigationData}
          />
        </Stack>
        <SocialLinks {...socialLinkData} />
      </Stack>
      <Copyright />
    </Stack >
  );
};