import { FC } from 'react';
import { Stack, Typography } from '@mui/joy';
import { WalletIcon } from '@shared/icons';
import { useBlockchain } from '../lib';

export const WithdrawableAmount: FC = () => {
  const blockchain = useBlockchain();
  return (
    <Stack
      flexDirection='row'
      alignItems='center'
      justifyContent='center'
      sx={{
        columnGap: 1,
      }}>
      <WalletIcon sx={{ height: 24, width: 24 }} />
      <Typography sx={{
        fontFamily: 'Poppins',
        fontSize: 16,
        fontWeight: 300,
        lineHeight: '24px',
        textAlign: 'left'
      }}>
        {blockchain.balance}
      </Typography>
    </Stack >
  );
};