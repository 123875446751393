import { FC } from 'react';
import {
  Accordion,
  AccordionSummary,
  AccordionDetails,
  Typography
} from '@mui/joy';
import { FaqCardIndicator } from './faq-card-indicator.component';

export type FaqCardProps = {
  summary: string;
  details: string;
};

export const FaqCard: FC<FaqCardProps> = ({ summary, details }) => {
  return (
    <Accordion
      sx={({ palette }) => ({
        paddingInline: 4,
        paddingBlock: 3,
        borderRadius: 16,
        borderWidth: 1,
        borderStyle: 'solid',
        borderColor: palette.common[300],
        background: palette.common[200],
        '&.Mui-expanded': {
          rowGap: 2,
          borderColor: palette.common[700],
        }
      })}>
      <AccordionSummary
        indicator={(
          <FaqCardIndicator />
        )}
        sx={({ palette }) => ({
          'button.MuiAccordionSummary-button:hover': {
            background: palette.common[200],
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'space-between'
          }
        })}>
        <Typography
          sx={({ fontSize, palette, breakpoints }) => ({
            fontFamily: 'Michroma',
            fontSize: fontSize.lg,
            lineHeight: '22.75px',
            color: palette.common.white,
            fontWeight: 400,
            [breakpoints.down(1024)]: {
              fontSize: fontSize.md,
              lineHeight: '25.59px'
            }
          })}>
          {summary}
        </Typography>
      </AccordionSummary>
      <AccordionDetails>
        <Typography
          sx={({ palette }) => ({
            fontFamily: 'Michroma',
            fontSize: 14,
            fontWeight: 300,
            color: palette.common[400],
          })}>
          {details}
        </Typography>
      </AccordionDetails>
    </Accordion>
  );
};