import { FC } from 'react';
import { Stack, Box, styled } from '@mui/joy';
import { OrderProcessStep } from './order-process-step.component';

const OrderProcessStepDivider = styled(Box)(({ theme }) => ({
  width: 1,
  background: theme.palette.common[300],
}));

export const OrderProcessStepper: FC = () => {
  return (
    <Stack
      direction='column'
      alignItems='center'
      sx={({ breakpoints }) => ({
        height: '100%',
        [breakpoints.down(769)]: {
          display: 'none'
        }
      })}>
      <OrderProcessStep>1</OrderProcessStep>
      <OrderProcessStepDivider height={291} />
      <OrderProcessStep>2</OrderProcessStep>
      <OrderProcessStepDivider height={278} />
      <OrderProcessStep>3</OrderProcessStep>
      <OrderProcessStepDivider height={337} />
      <OrderProcessStep>4</OrderProcessStep>
    </Stack>
  );
};
