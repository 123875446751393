import { ComponentType } from 'react';
import { BlockchainProvider } from '../lib';

export const withBlockchainProvider = <P extends object>(
  WrappedComponent: ComponentType<P>
) => (props: any) => (
  <BlockchainProvider
    contractAddress={process.env.REACT_APP_SMART_CONTRACT_ADDRESS!}
    ether={process.env.REACT_APP_DEFAULT_ETHERS!}
    appHost={process.env.REACT_APP_SPA_CLIENT!}>
    <WrappedComponent {...props} />
  </BlockchainProvider>
);