import { FC, ReactNode, useState } from 'react';
import { Button, ButtonProps } from '@mui/joy';
import { useSnackbar } from '@shared/lib';
import { useBlockchain } from '@entities/blockchain';

export type WithdrawButtonProps = ButtonProps & {
  content?: ReactNode | string;
  sizeVariant?: 'default' | 'big';
};

export const WithdrawButton: FC<WithdrawButtonProps> = ({
  sizeVariant = 'default',
  content = 'Withdraw',
  sx,
  ...props
}) => {
  const snackbar = useSnackbar();
  const blockchain = useBlockchain();
  const [isWithdrawing, setIsWithdrawing] = useState(false);

  const handleWithdraw = async (): Promise<void> => {
    if (isWithdrawing) return;
    setIsWithdrawing(true);

    try {
      await blockchain.withdrawFunds();
      snackbar.success({
        message: 'Funds successfully withdrawn!',
        autoHideDuration: 3000
      });
    } catch (error: any) {
      console.log('TEST', error)
      snackbar.error({
        autoHideDuration: 3000,
        message: error && error?.message.includes('User denied transaction signature')
          ? `You've decided to cancel the transaction. Feel free to initiate it again whenever you're ready.`
          : error?.message || 'Failed to withdraw funds. Please check your connection and try again.',
      });
    } finally {
      setIsWithdrawing(false);
    }
  };

  return (
    <Button
      color='primary'
      onClick={handleWithdraw}
      sx={[
        ({ breakpoints }) => ({
          zIndex: 1031,
          ...(sizeVariant === 'default' && ({
            height: 48,
            paddingInline: '4rem !important',
            width: 'fit-content',
            [breakpoints.down(768)]: {
              paddingInline: '1rem !important',
              height: 37,
              fontSize: 14,
              lineHeight: '21px',
            },
          })),
          ...(sizeVariant === 'big' && ({
            height: 48,
            paddingInline: '4rem !important',
          }))
        }),
        ...(Array.isArray(sx) ? sx : [sx])
      ]}
      {...props}>
      {content}
    </Button>
  );
};
