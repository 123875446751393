import { FC } from 'react';
import {
  Stack,
  Link,
  LinkProps
} from '@mui/joy';
import {
  DiscordIcon,
  TwitterIcon,
  TelegramIcon
} from '@shared/icons';

export type SocialLinksProps = {
  discordLinkProps?: LinkProps;
  twitterLinkProps?: LinkProps;
  telegramLinkProps?: LinkProps;
};

export const SocialLinks: FC<SocialLinksProps> = ({
  telegramLinkProps,
  discordLinkProps,
  twitterLinkProps,
}) => (
  <Stack
    direction='row'
    alignItems='center'
    justifyContent='space-between'
    columnGap={3}
    sx={{
      width: 108,
    }}>
    {telegramLinkProps && (
      <Link target='_blank' {...telegramLinkProps}>
        <TelegramIcon sx={{ fontSize: 24 }} />
      </Link>
    )}
    {discordLinkProps && (
      <Link target='_blank' {...discordLinkProps}>
        <DiscordIcon sx={{ fontSize: 24 }} />
      </Link>
    )}
    {twitterLinkProps && (
      <Link target='_blank' {...twitterLinkProps}>
        <TwitterIcon sx={{ fontSize: 24 }} />
      </Link>
    )}
  </Stack>
);