import { FC, ReactNode } from 'react';
import { Button, ButtonProps } from '@mui/joy';
import { useSnackbar } from '@shared/lib';
import { useBlockchain } from '@entities/blockchain';

export type GetReferralLinkButtonProps = ButtonProps & {
  content?: ReactNode | string;
};

export const GetReferralLinkButton: FC<GetReferralLinkButtonProps> = ({
  content = 'Get referral link',
  sx,
  ...props
}) => {
  const snackbar = useSnackbar();
  const blockchain = useBlockchain();

  const handleGetReferralLink = async (): Promise<void> => {
    try {
      const referralLink = await blockchain.getReferralLink();
      if (navigator.clipboard) {
        await navigator.clipboard.writeText(referralLink);
        snackbar.success({
          message: 'Referral link copied to clipboard!',
          autoHideDuration: 3000
        });
      } else {
        snackbar.error({
          message: 'Clipboard access is not available.',
          autoHideDuration: 3000
        });
      }
    } catch (error: any) {
      snackbar.error({
        message: error?.message || 'Unknown error',
        autoHideDuration: 3000
      });
    }
  };

  return (
    <Button
      color='third'
      onClick={handleGetReferralLink}
      sx={[
        {
          width: 'fit-content',
          paddingBlock: 1.5,
          paddingInline: 4,
          zIndex: 16,
        },
        ...(Array.isArray(sx) ? sx : [sx])
      ]}
      {...props}>
      {content}
    </Button>
  );
};