import { FC } from 'react';
import { Box, Stack } from '@mui/joy';
import { AddIcon, RemoveIcon } from '@shared/icons';

export const FaqCardIndicator: FC = () => (
  <Box
    sx={{
      height: 24,
      width: 24,
      '.Mui-expanded & > .collapseIconWrapper': {
        display: 'none',
      },
      '.expandIconWrapper': {
        display: 'none',
      },
      '.Mui-expanded & > .expandIconWrapper': {
        display: 'block',
      },
    }}>
    <Stack
      className='expandIconWrapper'
      direction='column'
      alignItems='center'
      justifyContent='center'>
      <RemoveIcon sx={{ height: 24, width: 24, }} />
    </Stack>
    <Stack className='collapseIconWrapper'
      direction='column'
      alignItems='center'
      justifyContent='center'>
      <AddIcon sx={{ height: 24, width: 24, }} />
    </Stack>
  </Box>
);