import { FC } from 'react';
import { Box, BoxProps } from '@mui/joy';

export type OrderProcessStepImgProps = BoxProps & {
  src?: Maybe<string>;
};

export const OrderProcessStepImg: FC<OrderProcessStepImgProps> = ({
  src,
  sx,
  ...props
}) => (
  <Box
    component='img'
    src={src ?? 'assets/images/step-chain.webp'}
    sx={[
      ({ breakpoints }) => ({
        width: 334,
        [breakpoints.down(594)]: {
          marginLeft: -5
        },
        [breakpoints.down(566)]: {
          marginLeft: -10
        },
        [breakpoints.down(510)]: {
          marginLeft: -18
        },
      }),
      ...(Array.isArray(sx) ? sx : [sx])
    ]}
    {...props}
  />
);