import { FC } from 'react';
import {
  Stack,
  StackProps,
  Link,
  LinkProps
} from '@mui/joy';

export type NavbarItemProps = LinkProps & {
  text: string;
  slug: string;
};

export type NavbarProps = StackProps & {
  bolder?: boolean;
  vertical?: boolean;
  hideForMobile?: boolean;
  hideForTablet?: boolean;
  items: NavbarItemProps[];
  onClick?(): void;
};

export const Navbar: FC<NavbarProps> = ({
  items,
  bolder = false,
  vertical = false,
  hideForMobile = false,
  hideForTablet = false,
  onClick,
  sx,
  ...props
}) => {
  return (
    <Stack
      direction='row'
      alignItems='center'
      justifyContent='flex-start'
      sx={[({ breakpoints }) => ({
        columnGap: 4,
        [breakpoints.down(768)]: {
          columnGap: 3,
          rowGap: 1,
          flexFlow: 'row wrap',
          ...(hideForMobile && ({
            display: 'none'
          }))
        },
        ...(vertical && ({
          flexFlow: 'column !important',
          rowGap: '5rem !important',
        })),
        ...(hideForTablet && ({
          [breakpoints.down(890)]: {
            display: 'none'
          }
        })),
      }),
      ...(Array.isArray(sx) ? sx : [sx])
      ]}
      {...props}>
      {items.map(({ text, slug, ...props }, index) => (
        <Link
          color='primary'
          key={index}
          href={slug}
          underline='none'
          onClick={onClick}
          sx={[
            { ...(bolder && { fontWeight: 500, }) },
            (({ breakpoints, fontSize }) => ({
              fontSize: fontSize.md,
              [breakpoints.down(768)]: {
                fontSize: 14,
                lineHeight: '21px',
                fontWeight: 300,
              }
            })),
            ...(props?.sx && Array.isArray(props?.sx)
              ? props?.sx
              : [props?.sx])
          ]}
          {...props}>
          {text}
        </Link>
      ))}
    </Stack>
  );
};