import { FC, PropsWithChildren, useMemo, useState } from "react";
import { Snackbar } from "../ui";
import { Snack, SnackOptions, SnackColor } from "./types";
import { SnackbarContext } from "./context";

export const SnackbarProvider: FC<PropsWithChildren> = ({ children }) => {
  const [toasts, setToasts] = useState<Array<Snack>>([])

  const handleOpen = (type: SnackColor) => (data: SnackOptions) => {
    setToasts((state) =>
      [...state, {
        id: new Date().getTime(),
        type,
        message: data.message,
        autoHideDuration: data.autoHideDuration || 3000
      }]
    )
  }

  const handleClose = () => {
    const newToasts = toasts.slice(1);
    setToasts(newToasts)
  }

  const activeToast = useMemo(() => toasts[0], [toasts])

  return (
    <SnackbarContext.Provider value={{
      error: handleOpen('danger'),
      success: handleOpen('success'),
    }
    } >
      {children}
      {
        activeToast && (
          <Snackbar
            key={activeToast.id}
            open={!!activeToast}
            onClose={handleClose}
            message={activeToast.message}
            autoHideDuration={activeToast.autoHideDuration}
            color={activeToast.type}
          />
        )
      }
    </SnackbarContext.Provider>
  );
};

