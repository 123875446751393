import { FC } from 'react';
import { SvgIcon, SvgIconProps } from '@mui/joy';

export const DiscordIcon: FC<SvgIconProps> = (props) => {
  return (
    <SvgIcon
      width='24'
      height='24'
      viewBox='0 0 24 24'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
      {...props}>
      <path
        d='M19.27 5.33005C17.94 4.71005 16.5 4.26005 15 4.00005C14.9868 3.99963 14.9738 4.00209 14.9617 4.00728C14.9496 4.01246 14.9388 4.02023 14.93 4.03005C14.75 4.36005 14.54 4.79005 14.4 5.12005C12.809 4.88005 11.191 4.88005 9.6 5.12005C9.46 4.78005 9.25 4.36005 9.06 4.03005C9.05 4.01005 9.02 4.00005 8.99 4.00005C7.49 4.26005 6.06 4.71005 4.72 5.33005C4.71 5.33005 4.7 5.34005 4.69 5.35005C1.97 9.42005 1.22 13.38 1.59 17.3C1.59 17.32 1.6 17.34 1.62 17.35C3.42 18.67 5.15 19.47 6.86 20C6.89 20.01 6.91999 20 6.93 19.98C7.33 19.43 7.69 18.85 8 18.24C8.02 18.2 8 18.16 7.96 18.15C7.39 17.93 6.85 17.67 6.32 17.37C6.28 17.35 6.27999 17.29 6.31 17.26C6.42 17.18 6.52999 17.09 6.64 17.01C6.66 16.99 6.69 16.99 6.71 17C10.15 18.57 13.86 18.57 17.26 17C17.28 16.99 17.31 16.99 17.33 17.01C17.44 17.1 17.55 17.18 17.66 17.27C17.7 17.3 17.7 17.36 17.65 17.38C17.13 17.69 16.58 17.94 16.01 18.16C15.97 18.17 15.96 18.22 15.97 18.25C16.29 18.86 16.65 19.44 17.04 19.99C17.07 20 17.1 20.01 17.13 20C18.85 19.47 20.58 18.67 22.38 17.35C22.4 17.34 22.41 17.32 22.41 17.3C22.85 12.77 21.68 8.84005 19.31 5.35005C19.3 5.34005 19.29 5.33005 19.27 5.33005ZM8.52 14.91C7.49 14.91 6.63 13.96 6.63 12.79C6.63 11.62 7.47 10.67 8.52 10.67C9.58 10.67 10.42 11.63 10.41 12.79C10.41 13.96 9.57 14.91 8.52 14.91ZM15.49 14.91C14.46 14.91 13.6 13.96 13.6 12.79C13.6 11.62 14.44 10.67 15.49 10.67C16.55 10.67 17.39 11.63 17.38 12.79C17.38 13.96 16.55 14.91 15.49 14.91Z'
        fill='white'
      />
    </SvgIcon>
  );
};