import { FC } from 'react';
import {
  Stack,
  Typography,
  Avatar,
} from '@mui/joy';

export type LogoProps = {
  mobileAdaptive?: boolean;
};

export const Logo: FC<LogoProps> = ({ mobileAdaptive = false }) => (
  <Stack
    direction='row'
    alignItems='center'
    justifyContent='space-between'
    component='a'
    href='/'
    sx={({ breakpoints }) => ({
      columnGap: 2,
      width: 195,
      textDecoration: 'none',
      ...(mobileAdaptive && ({
        [breakpoints.down(768)]: {
          width: 133,
          columnGap: 1,
        },
        [breakpoints.down(768)]: {
          width: '100%',
          columnGap: 'unset',
        },
      }))
    })}>
    <Avatar
      src='/assets/images/logo-small.webp'
      sx={({ breakpoints }) => ({
        background: 'none',
        width: 49,
        height: 42,
        borderRadius: 0,
        ...(mobileAdaptive && ({
          [breakpoints.down(768)]: {
            width: 27,
            height: 24
          }
        }))
      })}
    />
    <Typography
      sx={({ palette, fontSize, breakpoints }) => ({
        fontSize: fontSize.md,
        fontFamily: 'Michroma',
        fontStyle: 'normal',
        fontWeight: 400,
        lineHeight: '22.75px',
        color: palette.common.white,
        ...(mobileAdaptive && ({
          [breakpoints.down(768)]: {
            fontSize: 12,
            lineHeight: '17.06px'
          },
          [breakpoints.down(414)]: {
            display: 'none'
          }
        }))
      })}>
      MoneyChain
    </Typography>
  </Stack>
);