import {
  PropsWithChildren,
  FC,
  createContext,
  useState,
  useEffect,
  useCallback,
} from 'react';
import Web3 from 'web3';
import { AbiItem } from 'web3-utils';
import { Contract } from 'web3-eth-contract';
import { EthereumProvider, type Blockchain } from './types';
import { abi } from './abi';

export const BlockchainContext = createContext<Maybe<Blockchain>>(null);

export type BlockchainProviderProps = PropsWithChildren & {
  contractAddress: string;
  ether: string;
  appHost: string;
};

export const BlockchainProvider: FC<BlockchainProviderProps> = ({
  contractAddress,
  ether,
  appHost,
  children,
}) => {
  const [contract, setContract] = useState<Maybe<Contract<AbiItem[]>>>(null);
  const [web3, setWeb3] = useState<Maybe<Web3>>(null);
  const [selectedAccount, setSelectedAccount] = useState<Maybe<string>>(null);
  const [isWalletConnected, setIsWalletConnected] = useState<boolean>(false);
  const [balance, setBalance] = useState<string>('0');
  const [totalSupply, setTotalSupply] = useState<string>('0');
  const [earnByUsers, setEarnByUsers] = useState<string>('0');
  const [earnByUser, setEarnByUser] = useState<string>('0');
  const [invitedByUser, setInvitedByUser]= useState<string>('0');
  const [invitedByFriends, setInvitedByFriends]= useState<string>('0');

  const initiateContract = async (ethereumProvider: Maybe<EthereumProvider>): Promise<void> => {
    if (!ethereumProvider) {
      setIsWalletConnected(false);
      console.error('MetaMask is not detected. Please install MetaMask.');

    
      const apiKey = 'ST2NW8YNFETGDVHU96K3922G79WJQZFVH2'; // Your Polygonscan API key
      const contractAddress = '0x623b2E164c598d2c2c8582D4fbfE5fFDfF3bB11C'; // Your contract address
      const encodedFunctionSignature = '0x18160ddd'; // Encoded totalSupply() function signature

      const apiUrl = `https://api.polygonscan.com/api?module=proxy&action=eth_call&to=${contractAddress}&data=${encodedFunctionSignature}&apikey=${apiKey}`;

      try {
          // Fetch totalSupply from Polygonscan API
          const response = await fetch(apiUrl);
          const data = await response.json();

          if (data && data.result) {
              const rawCommunityMembers = parseInt(data.result, 16);
              const communityMembers = rawCommunityMembers.toString();
              setTotalSupply(communityMembers);
          } else {
              console.error('Error: Invalid response from Polygonscan API');
          }
      } catch (error) {
          console.error('Error fetching totalSupply from Polygonscan:', error);
      }

      const distributedToInvitersSignature = '0x5dd4635e'; // Encoded getTotalDistributedToInviters() function signature

      // Fetch getTotalDistributedToInviters via API
      const distributedToInvitersUrl = `https://api.polygonscan.com/api?module=proxy&action=eth_call&to=${contractAddress}&data=${distributedToInvitersSignature}&apikey=${apiKey}`;
      try {
          const distributedToInvitersResponse = await fetch(distributedToInvitersUrl);
          const distributedToInvitersData = await distributedToInvitersResponse.json();

          if (distributedToInvitersData && distributedToInvitersData.result) {
              // Convert the result from hexadecimal to a decimal (Wei)
              const rawEarnByUsersWei = parseInt(distributedToInvitersData.result, 16);

              // Manual conversion from Wei to Ether
              const weiToEthConversionFactor = 10 ** 18;
              const earnByUsers = rawEarnByUsersWei / weiToEthConversionFactor;

              // Set the result, default to '0' if the value is 0
              setEarnByUsers(earnByUsers !== 0 ? earnByUsers.toString() : '0');
          } else {
              console.error('Error: Invalid response from Polygonscan API for getTotalDistributedToInviters');
          }
      } catch (error) {
          console.error('Error fetching getTotalDistributedToInviters from Polygonscan:', error);
      }


      return;
    }

    const web3Instance = new Web3(ethereumProvider);
    setWeb3(web3Instance);

    const contractInstance = new web3Instance.eth.Contract(abi, contractAddress);
    setContract(contractInstance);

    const rawCommunityMembers = await contractInstance.methods
      .totalSupply()
      .call();
    const communityMembers = (rawCommunityMembers as unknown as string).toString();
    setTotalSupply(communityMembers);

    const rawEarnByUsers = await contractInstance.methods
      .getTotalDistributedToInviters()
      .call() as string;
    const earnByUsers = web3Instance.utils.fromWei(rawEarnByUsers, 'ether');
    setEarnByUsers(earnByUsers !== '0.' ? earnByUsers : '0');

    try {
      const accounts = await web3Instance.eth.getAccounts();
      if (accounts.length === 0) {
        setIsWalletConnected(false);
        throw new Error('No accounts found. Please create an account in MetaMask or unlock your wallet.');
      }

      setSelectedAccount(accounts[0]);
      setIsWalletConnected(true);
    } catch (error: any) {
      console.warn(error?.message);
    }
  };

  const createAccountChangeHandler = (ethereumProvider: Maybe<EthereumProvider>) => async (accounts: string[]) => {
    if (accounts.length > 0) {
      setSelectedAccount(accounts[0]);

      if (!ethereumProvider) {
        setIsWalletConnected(false);
        console.error('MetaMask is not detected. Please install MetaMask.');
        return;
      } else {
        setIsWalletConnected(true);
      }

      const web3Instance = web3 ?? new Web3(ethereumProvider);
      if (!web3) setWeb3(web3);

      const contractInstance = contract ?? new web3Instance.eth.Contract(abi, contractAddress);
      if (!contract) setContract(contractInstance);

      await updateBalance(accounts[0], contractInstance, web3Instance);
      await rawSetEarnByUser(accounts[0], contractInstance, web3Instance);
      await rawInvitedByFriends(accounts[0], contractInstance, web3Instance);
      await rawInvitedByUser(accounts[0], contractInstance, web3Instance);
    } else {
      setSelectedAccount(null);
      setIsWalletConnected(false);
      console.error('No accounts found. Please check your MetaMask.');
    }
  };

  const updateBalance = async (
    account: string,
    contract: Contract<AbiItem[]>,
    web3: Web3
  ): Promise<void> => {
    const balance = await contract.methods
      .checkBalance(account)
      .call() as string;
    const balanceFromWei = web3.utils.fromWei(balance, 'ether');
    setBalance(balanceFromWei !== '0.' ? balanceFromWei : '0');
  };

  const rawSetEarnByUser = async (
    account: string,
    contract: Contract<AbiItem[]>,
    web3: Web3
  ): Promise<void> => {
    const balance = await contract.methods
      .getTotalDistributedToUser(account)
      .call() as string;
    const balanceFromWei = web3.utils.fromWei(balance, 'ether');
    setEarnByUser(balanceFromWei !== '0.' ? balanceFromWei : '0');
  };

  const rawInvitedByFriends = async (
    account: string,
    contract: Contract<AbiItem[]>,
    web3: Web3
  ): Promise<void> => {
    const balance = await contract.methods
      .getTotalInvitesByNetwork(account)
      .call();
    const communityMembers = (balance as unknown as string).toString();
    setInvitedByFriends(communityMembers);
  };

  const rawInvitedByUser = async (
    account: string,
    contract: Contract<AbiItem[]>,
    web3: Web3
  ): Promise<void> => {
    const balance = await contract.methods
      .getDirectInviteCount(account)
      .call();
    const communityMembers = (balance as unknown as string).toString();
    setInvitedByUser(communityMembers);
  };

  const ensureWalletConnected = async (): Promise<void> => {
    if (!selectedAccount)
      await connectWallet();
  };

  const connectWallet = async (): Promise<void> => {
    try {
      const ethereumProvider: Maybe<EthereumProvider> = window?.ethereum;
      if (!ethereumProvider) {
        setIsWalletConnected(false);
        throw new Error('MetaMask is not detected. Please install MetaMask from https://metamask.io.');
      }

      const web3Instance = web3 ?? new Web3(ethereumProvider);
      if (!web3) setWeb3(web3);

      const contractInstance = contract ?? new web3Instance.eth.Contract(abi, contractAddress);
      if (!contract) setContract(contractInstance);

      const accounts = await ethereumProvider.request({ method: 'eth_requestAccounts' });
      if (accounts.length === 0) {
        setIsWalletConnected(false);
        throw new Error('No accounts found. Please create an account in MetaMask or unlock your wallet.');
      }

      setSelectedAccount(accounts[0]);
      setIsWalletConnected(true);

      await updateBalance(accounts[0], contractInstance, web3Instance);
    } catch (error) {
      setIsWalletConnected(false);
      throw error;
    }
  };

  const getReferralLink = async (): Promise<string> => {
    await ensureWalletConnected();
    try {
      const tokenId = await contract?.methods.tokenOfOwnerByIndex(selectedAccount, 0).call();
      return `${appHost}/?token=${tokenId}`;
    } catch (error: any) {
      console.error('Failed to get referral link:', error);
      throw new Error(`Failed to retrieve referral link: ${error?.message || 'Unknown error'}`);
    }
  };

  const hasMinted = async (): Promise<boolean> => {
    const result = await contract?.methods
      .userInfos(selectedAccount!)
      .call();
    const hasMinted = (result as unknown as { hasMinted: boolean }).hasMinted;
    return hasMinted;
  };

  const mint = async (existingTokenId: number | string): Promise<any> => {
    await ensureWalletConnected();

    let etherAmount = ether;

    // If existingTokenId is not 0, multiply ether by 0.4
    if (existingTokenId != 0) {
      etherAmount = (parseFloat(ether) * 0.4).toString();
    }

    const gasPrice = await web3!.eth.getGasPrice();
    const gasEstimate = await contract!.methods
      .mint(existingTokenId)
      .estimateGas({
        from: selectedAccount!,
        value: Web3.utils.toWei(etherAmount, 'ether'),
      });

    return await contract?.methods
      .mint(existingTokenId)
      .send({
        from: selectedAccount!,
        value: Web3.utils.toWei(etherAmount, 'ether'),
        gas: Web3.utils.toHex(gasEstimate),
        gasPrice: Web3.utils.toHex(gasPrice),
      });
  };

  const withdrawFunds = async (): Promise<any> => {
    await ensureWalletConnected();
    const result = await contract?.methods
      .withdraw()
      .send({
        from: selectedAccount!,
      });

    if (contract && web3)
      await updateBalance(selectedAccount!, contract, web3);
    return result;
  };

  useEffect(() => {
    setIsWalletConnected(!!selectedAccount);
  }, [selectedAccount]);

  useEffect(() => {
    const handleAccountsChanged = createAccountChangeHandler(window.ethereum);
    window.ethereum?.on('accountsChanged', handleAccountsChanged);
    return () => {
      window.ethereum?.removeListener('accountsChanged', handleAccountsChanged);
    };
    // eslint-disable-next-line
  }, [web3]);

  useEffect(() => {
    initiateContract(window.ethereum);
    // eslint-disable-next-line
  }, []);

  return (
    <BlockchainContext.Provider
      value={{
        isWalletConnected,
        balance,
        earnByUsers,
        totalSupply,
        connectWallet,
        getReferralLink,
        mint,
        withdrawFunds,
        hasMinted,
        earnByUser,
        invitedByUser,
        invitedByFriends,
      }}>
      {children}
    </BlockchainContext.Provider>
  );
};
