import { FC, ReactNode } from 'react';
import {
  Box,
  Stack,
  StackProps,
  Typography
} from '@mui/joy';

export type WhyUsCardProps = StackProps & {
  title: string;
  subTitle: string;
  imgSrc?: ReactNode;
};

export const WhyUsCard: FC<WhyUsCardProps> = ({
  title,
  subTitle,
  imgSrc,
  sx,
  ...props
}) => (
  <Stack
    direction='column'
    alignItems='flex-start'
    justifyContent='flex-start'
    sx={[
      ({ palette, breakpoints }) => ({
        position: 'relative',
        rowGap: 2,
        borderRadius: 16,
        padding: 3,
        maxWidth: 287,
        height: 478,
        borderStyle: 'solid',
        borderWidth: 1,
        borderColor: palette.common[300],
        background: palette.common[200],
        [breakpoints.down(1229)]: {
          maxWidth: 'unset',
          maxHeight: 403,
        },
        [breakpoints.down(1024)]: {
          height: 'unset',
          paddingBlock: 4,
        },
      }),
      ...(Array.isArray(sx) ? sx : [sx])
    ]}
    {...props}>
    <Typography
      sx={({ fontSize, palette, breakpoints }) => ({
        fontFamily: 'Michroma',
        fontSize: fontSize.lg,
        color: palette.common.white,
        [breakpoints.down(1024)]: { fontSize: fontSize.md }
      })}>
      {title}
    </Typography>
    <Typography
      sx={({ fontSize, palette, breakpoints }) => ({
        fontFamily: 'Poppins',
        fontSize: fontSize.md,
        color: palette.common[400],
        [breakpoints.down(1024)]: { fontSize: 14 }
      })}>
      {subTitle}
    </Typography>
    {
      imgSrc && (
        <Stack
          alignItems='center'
          justifyContent='flex-end'
          sx={{
            width: '100%',
            height: '100%',
          }}>
          <Box
            component='img'
            src={imgSrc}
            sx={[
              ({ breakpoints }) => ({
                width: 246,
                height: 246,
                [breakpoints.down(1024)]: {
                  display: 'none'
                }
              }),
              ...(Array.isArray(sx) ? sx : [sx])
            ]}
            {...props}
          />
        </Stack>
      )
    }
  </Stack >
);