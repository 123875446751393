import { FC } from 'react';
import {
  Stack,
  StackProps,
  Typography
} from '@mui/joy';

export type WhyUsStatsCardProps = StackProps & {
  title: string;
  subTitle: string;
};

export const WhyUsStatsCard: FC<WhyUsStatsCardProps> = ({
  title,
  subTitle,
  sx,
  ...props
}) => {
  return (
    <Stack
      direction='column'
      alignItems='flex-start'
      justifyContent='space-between'
      rowGap={2}
      sx={[
        ({ breakpoints }) => ({
          [breakpoints.down(420)]: {
            alignItems: 'center'
          }
        }),
        ...(Array.isArray(sx) ? sx : [sx])
      ]}
      {...props}>
      <Typography
        sx={({ fontSize, palette, breakpoints }) => ({
          fontFamily: 'Michroma',
          fontSize: fontSize.xl3,
          color: palette.common.white,
          [breakpoints.down(1229)]: { fontSize: fontSize.xl }
        })}>
        {title}
      </Typography>
      <Typography
        sx={({ fontSize, palette, breakpoints }) => ({
          fontFamily: 'Michroma',
          fontSize: fontSize.lg,
          color: palette.common.white,
          [breakpoints.down(1229)]: { fontSize: 14 }
        })}>
        {subTitle}
      </Typography>
    </Stack>
  );
};