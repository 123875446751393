import { FC, ReactNode } from 'react';
import { Stack, StackProps, Typography } from '@mui/joy';
import { HowItWorksCard } from './how-it-works-card.component';
import { OrderProcessStepper } from './order-progress-stepper.component';
import { OrderProcessStepImg } from './order-process-step-img.component';

export type HowItWorksProps = StackProps & {
  cardsContent: Array<{
    title: string;
    subTitle: string;
    slotAction: ReactNode;
  }>;
};

export const HowItWorks: FC<HowItWorksProps> = ({
  cardsContent,
  sx,
  ...props
}) => (
  <Stack
    id='how-it-works'
    component='div'
    direction='column'
    alignItems='center'
    justifyContent='center'
    sx={[
      ({ palette, breakpoints }) => ({
        paddingInline: 2,
        paddingBlock: 13,
        rowGap: 12.75,
        background: palette.common[100],
        minHeight: 1649,
        [breakpoints.down(769)]: {
          paddingBlock: 9,
        }
      }),
      ...(Array.isArray(sx) ? sx : [sx])
    ]}
    {...props}>
    <Typography
      sx={({ palette, breakpoints, fontSize }) => ({
        fontFamily: 'Michroma',
        fontSize: fontSize.xl3,
        lineHeight: '56.88px',
        color: palette.common.white,
        fontWeight: 400,
        textAlign: 'center',
        [breakpoints.down(1024)]: {
          fontSize: fontSize.xl,
          lineHeight: '34.13px'
        }
      })}>
      How it works
    </Typography>
    <Stack
      direction='row'
      alignItems='flex-start'
      justifyContent='center'
      sx={{
        width: '100%',
        maxWidth: 1200,
      }}>
      <Stack
        direction='column'
        alignItems='flex-start'
        justifyContent='flex-start'
        sx={({ breakpoints }) => ({
          marginRight: '70px',
          [breakpoints.down(458)]: {
            maxWidth: 127
          }
        })}>
        <OrderProcessStepImg
          src='assets/images/platfromschain-1.webp'
          sx={{
            marginTop: '-84px',
            marginBottom: '17px',
          }}
        />
        <OrderProcessStepImg
          src='assets/images/platfromschain-2.webp'
          sx={{
            marginBottom: '25px',
          }}
        />
        <OrderProcessStepImg
          src='assets/images/platfromschain-3.webp'
          sx={{
            marginBottom: '65px'
          }}
        />
        <OrderProcessStepImg
          src='assets/images/platfromschain-4.webp'
          sx={{
          }}
        />
      </Stack>
      <Stack
        direction='row'
        alignItems='flex-start'
        justifyContent='center'
        columnGap={5}>
        <OrderProcessStepper />
        <Stack
          direction='column'
          alignItems='center'
          justifyContent='center'
          sx={({ breakpoints }) => ({
            rowGap: 19,
            width: 'fit-content',
            maxWidth: 1200,
            [breakpoints.down(769)]: {
              rowGap: 6
            }
          })}>
          {cardsContent.map(({ ...props }, index) => (
            <HowItWorksCard
              key={index}
              step={index + 1}
              {...props}
            />
          ))}
        </Stack>
      </Stack>
    </Stack>
  </Stack>
);