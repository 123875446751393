import { FC } from 'react';
import { Box, Stack, Typography } from '@mui/joy';
import { MintButton } from '@features/mint';

export const CallToActionSection: FC = () => (
  <Stack
    direction='column'
    alignItems='center'
    justifyContent='center'
    sx={({ palette }) => ({
      position: 'relative',
      maxWidth: 1200,
      width: '100%',
      height: 357,
      borderRadius: 16,
      borderStyle: 'solid',
      borderWidth: 1,
      borderColor: palette.common[300],
      overflow: 'hidden',
      zIndex: 25,
      opacity: 1.0,
    })}>
    <Box
      component='img'
      src='assets/images/cta-bg.webp'
      sx={({ palette }) => ({
        position: 'absolute',
        top: 0,
        right: 0,
        left: 0,
        bottom: 0,
        zIndex: 1,
        background: palette.common[100],
        opacity: 1.0
      })}
    />
    <Box
      sx={({ palette }) => ({
        position: 'absolute',
        top: 75,
        right: 0,
        left: '50%',
        bottom: 0,
        zIndex: 1,
        opacity: 1.0,
        width: '100%',
        maxWidth: 414,
        height: 200,
        transform: 'translate(-50%)',
        borderRadius: '50%',
        background: `linear-gradient(0deg, ${palette.common[50]}, ${palette.common[100]})`,
        filter: 'blur(90px)'
      })}
    />
    <Stack
      direction='column'
      alignItems='center'
      justifyContent='center'
      sx={{
        rowGap: 5,
        width: '100%',
        height: 'auto',
        padding: '20px',
        zIndex: 15,
      }}>
      <Typography
        sx={({ fontSize, palette, breakpoints }) => ({
          fontFamily: 'Michroma',
          fontSize: fontSize.xl3,
          fontWeight: 400,
          lineHeight: '56.88px',
          textAlign: 'center',
          color: palette.common.white,
          width: '100%',
          wordWrap: 'break-word',
          whiteSpace: 'normal',
          [breakpoints.down(768)]: {
            fontSize: fontSize.xl,
            lineHeight: '34.13px'
          }
        })}>
        Let’s start earning
      </Typography>
      <MintButton />
    </Stack>
  </Stack>
);