import { FC } from 'react';
import { Typography } from '@mui/joy';

export const Copyright: FC = () => (
  <Typography
    sx={({ palette, breakpoints }) => ({
      fontSize: 16,
      fontStyle: 'normal',
      fontWeight: 300,
      lineHeight: '24px',
      color: palette.common[400],
      textAlign: 'center',
      [breakpoints.down(768)]: {
        textAlign: 'left',
        wordWrap: 'break-word'
      }
    })}>
    &copy;&nbsp;{new Date().getFullYear()} MoneyChain. All rights reserved
  </Typography>
);