import {
  ComponentType
} from 'react';
import {
  CssBaseline,
  CssVarsProvider
} from '@mui/joy';
import {
  joyTheme
} from './joy-theme';

export const withJoyTheme =
  <T extends object>(
    Component: ComponentType<T>
  ) => (props: T) => (
    <CssVarsProvider
      defaultMode='dark'
      theme={joyTheme}
      colorSchemeSelector='#dark-mode-by-default'
      modeStorageKey='dark-mode-by-default'
      disableNestedContext>
      <CssBaseline />
      <Component {...props} />
    </CssVarsProvider>
  );
