import { FC } from 'react';
import { SvgIcon, SvgIconProps } from '@mui/joy';

export const CloseIcon: FC<SvgIconProps> = (props) => (
  <SvgIcon
    width='20'
    height='15'
    viewBox='0 0 20 15'
    fill='none'
    xmlns='http://www.w3.org/2000/svg'
    {...props}>
    <path d='M4 14L16.7279 1.27208' stroke='white' strokeLinecap='round' />
    <path d='M17 14L4.27208 1.27208' stroke='white' strokeLinecap='round' />
  </SvgIcon>
);