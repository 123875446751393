import { FC, useCallback, useState } from 'react';
import { Button, ButtonProps } from '@mui/joy';
import { useSnackbar } from '@shared/lib';
import { useBlockchain } from '@entities/blockchain';

export type ConnectWalletButtonProps = ButtonProps & {
  sizeVariant?: 'default' | 'big';
};

let test = 0

export const ConnectWalletButton: FC<ConnectWalletButtonProps> = ({
  sizeVariant = 'default',
  sx,
  ...props
}) => {
  const snackbar = useSnackbar();
  const blockchain = useBlockchain();
  const [isConnectingWallet, setIsConnectingWallet] = useState<boolean>(false);

  const handleConnectWallet = useCallback(async (): Promise<void> => {
    console.log(++test);
    if (isConnectingWallet) return;
    setIsConnectingWallet(true);

    try {
      await blockchain.connectWallet();
      snackbar.success({
        message: 'Success! Your wallet is now connected',
        autoHideDuration: 2000
      });
    } catch (error: any) {
      snackbar.error({
        message: error?.message || 'Failed to connect the wallet. Please try again.',
        autoHideDuration: 2000
      });
    } finally {
      setIsConnectingWallet(false);
    }
    // eslint-disable-next-line
  }, []);

  return (
    <Button
      color='primary'
      onClick={handleConnectWallet}
      sx={[
        ({ breakpoints }) => ({
          zIndex: 1031,
          ...(sizeVariant === 'default' && ({
            height: 48,
            paddingInline: '4rem !important',
            width: 'fit-content',
            [breakpoints.down(768)]: {
              paddingInline: '1rem !important',
              height: 37,
              fontSize: 14,
              lineHeight: '21px',
            },
          })),
          ...(sizeVariant === 'big' && ({
            height: 48,
            paddingInline: '4rem !important',
          }))
        }),
        ...(Array.isArray(sx) ? sx : [sx])
      ]}
      {...props}>
      Connect&nbsp;Wallet
    </Button >
  );
};