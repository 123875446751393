import { InfoOutlined, TaskAltOutlined } from '@mui/icons-material'
import { FC, useMemo } from 'react'
import { SnackColor } from '../lib/types'


const iconMap: Record<SnackColor, JSX.Element> = {
  danger: <InfoOutlined sx={{ fontSize: '18px' }} />,
  success: <TaskAltOutlined sx={{ fontSize: '18px' }} />
}

type SnackIconProps = {
  type: SnackColor
}

export const SnackIcon: FC<SnackIconProps> = ({ type }) => {
  const icon = useMemo(() => iconMap[type], [type])
  return icon;
}