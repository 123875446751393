import { LinkProps } from '@mui/joy';

export const socialLinkData: {
  discordLinkProps?: LinkProps,
  twitterLinkProps?: LinkProps,
  telegramLinkProps?: LinkProps
} = {
  discordLinkProps: {
    href: process.env.REACT_APP_DISCORD_SOCIAL_ACCOUNT ?? 'https://discord.gg/your-invite-code'
  },
  twitterLinkProps: {
    href: process.env.REACT_APP_TWITTER_SOCIAL_ACCOUNT ?? 'https://twitter.com/yourusername'
  },
  telegramLinkProps: {
    href: process.env.REACT_APP_TELEGRAM_SOCIAL_ACCOUNT ?? 'https://telegram.org/'
  }
};