import { FC } from 'react';
import { SvgIcon, SvgIconProps } from '@mui/joy';

export const AddIcon: FC<SvgIconProps> = (props) => (
  <SvgIcon
    width='24'
    height='24'
    viewBox='0 0 24 24'
    fill='none'
    xmlns='http://www.w3.org/2000/svg'
    {...props}>
    <path
      d='M1 13H25'
      stroke='#52A051'
      strokeWidth='2'
      strokeLinecap='round'
    />
    <path
      d='M13 1L13 25'
      stroke='#52A051'
      strokeWidth='2'
      strokeLinecap='round'
    />
  </SvgIcon>
);