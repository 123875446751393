import { FC } from 'react';
import compose from 'compose-function';
import { RouterProvider } from 'react-router-dom';
import { withSnackbar } from '@shared/lib';
import { withBlockchainProvider } from '@entities/blockchain';
import { withJoyTheme } from './theme';
import { appRouter } from './app-router';

export const withProviders = compose(
  withJoyTheme,
  withSnackbar,
  withBlockchainProvider
);

export const AppEntry: FC = withProviders(() => {
  return (
    <RouterProvider router={appRouter} />
  );
});