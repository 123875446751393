import { FC } from 'react';
import { Box, Stack, Typography } from '@mui/joy';
import { useBlockchain } from '@entities/blockchain';
import { WhyUsCard } from './why-us-card.component';
import { WhyUsStatsCard } from './why-us-stats-card.component';
import { WhyUsStatsSeparator } from './why-us-stats-separator.component';
import { calculateDaysSinceLaunch } from '../lib';

export type WhyUsProps = {
  cardsContent: Array<{
    title: string;
    subTitle: string;
    imgSrc?: string;
  }>;
};

export const WhyUs: FC<WhyUsProps> = ({ cardsContent }) => {
  const { totalSupply, earnByUsers } = useBlockchain();
  return (
    <Stack
      id='why-us'
      component='div'
      direction='column'
      alignItems='center'
      justifyContent='center'
      sx={({ breakpoints, palette }) => ({
        background: palette.common[100],
        paddingInline: 2,
        paddingBlock: 13,
        [breakpoints.down(769)]: {
          paddingBlock: 8.25,
          paddingBlockEnd: 9
        }
      })}>
      <Stack
        direction='column'
        sx={({ breakpoints }) => ({
          width: '100%',
          maxWidth: 1200,
          rowGap: 7,
          [breakpoints.down(768)]: {
            rowGap: 4
          }
        })}>
        <Typography
          sx={({ fontSize, breakpoints, palette }) => ({
            fontFamily: 'Michroma',
            fontSize: fontSize.xl3,
            lineHeight: '56.88px',
            color: palette.common.white,
            fontWeight: 400,
            textAlign: 'start',
            [breakpoints.down(1024)]: {
              fontSize: fontSize.xl,
              lineHeight: '34.13px'
            }
          })}>
          Why trust us?
        </Typography>
        <Box
          sx={({ breakpoints }) => ({
            display: 'grid',
            gridTemplateColumns: '1fr 1fr 1fr 1fr',
            gap: 2,
            [breakpoints.down(1229)]: {
              gridTemplateColumns: '1fr 1fr',
            },
            [breakpoints.down(697)]: {
              gridTemplateColumns: '1fr',
            }
          })}>
          {cardsContent.map(({ ...props }, index) => (
            <WhyUsCard key={index} {...props} />
          ))}
        </Box>
        <Stack
          direction='row'
          alignItems='center'
          justifyContent='space-between'
          sx={({ breakpoints }) => ({
            columnGap: 7,
            [breakpoints.down(796)]: {
              display: 'grid',
              gridTemplateColumns: '1fr 1fr',
              rowGap: 4
            },
            [breakpoints.down(420)]: {
              gridTemplateColumns: '1fr',
            }
          })}>
          <WhyUsStatsCard
            title={totalSupply}
            subTitle='Community Members'
          />
          <WhyUsStatsSeparator />
          <WhyUsStatsCard
            title={earnByUsers}
            subTitle='Earned by users'
          />
          <WhyUsStatsSeparator />
          <WhyUsStatsCard
            title={calculateDaysSinceLaunch(process.env.REACT_APP_LAUNCH_DATE ?? '0001-01-01')}
            subTitle='Days since launch'
          />
          <WhyUsStatsSeparator />
          <WhyUsStatsCard
            title={`${process.env.REACT_APP_FOR_EACH_FRIEND ?? 0} POL`}
            subTitle='For each friend'
          />
        </Stack>
      </Stack>
    </Stack>
  );
}