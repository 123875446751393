import { FC } from 'react';
import { Stack } from '@mui/joy';
import { CallToActionSection } from './call-to-action-section.component';
import { BlurryBackground } from './blurry-background.component';

export const CallToAction: FC = () => {
  return (
    <Stack
      direction='column'
      alignItems='center'
      justifyContent='center'
      sx={({ breakpoints, palette }) => ({
        position: 'relative',
        height: 507,
        paddingInline: 2,
        paddingBlock: 9.375,
        background: palette.common[100],
        [breakpoints.down(768)]: {
          paddingInline: 2,
          paddingBlock: 9,
        },
      })}>
      <BlurryBackground />
      <CallToActionSection />
    </Stack>
  );
};