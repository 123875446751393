import { FC } from 'react';
import { Stack } from '@mui/joy';
import { Logo, Navbar } from '@shared/ui';
import { navigationData } from '@shared/config';
import { userNavigationData } from '@shared/config';
import { WithdrawableAmount, useBlockchain } from '@entities/blockchain';
import { ConnectWalletButton } from '@features/connect-wallet';
import { WithdrawButton } from '@features/withdraw';
import { MobileMenu } from './mobile-menu.component';

export const Header: FC = () => {
  const blockchain = useBlockchain();
  return (
    <Stack
      direction='row'
      alignItems='center'
      justifyContent='center'
      sx={({ palette }) => ({
        zIndex: 15,
        rowGap: 8,
        paddingBlockStart: 3,
        paddingInline: 2,
        background: palette.common[100],
      })}>
      <Stack
        direction='row'
        alignItems='center'
        justifyContent='space-between'
        sx={({ breakpoints }) => ({
          columnGap: 7,
          width: '100%',
          maxWidth: 1200,
          marginInline: 'auto',
          [breakpoints.down(768)]: {
            alignItems: 'center',
            rowGap: 3,
            columnGap: 1,
          }
        })}>
        <Stack
          direction='row'
          alignItems='center'
          justifyContent='flex-start'
          sx={({ breakpoints }) => ({
            columnGap: 7,
            [breakpoints.down(768)]: {
              flexDirection: 'column',
              alignItems: 'flex-start',
              rowGap: 4
            }
          })}>
          <Logo mobileAdaptive />
          {blockchain.isWalletConnected ?
           (<Navbar
            hideForTablet
            items={userNavigationData}
          />) : (<Navbar
            hideForTablet
            items={navigationData}
          />)}
        </Stack>

        {blockchain.isWalletConnected ? (
          <Stack
            flexDirection='row'
            alignItems='center'
            justifyContent='center'
            sx={{
              columnGap: '24px'
            }}>
            <WithdrawableAmount />
            <WithdrawButton />
          </Stack>
        ) : (
          <ConnectWalletButton />
        )}

        <MobileMenu />
      </Stack>
    </Stack>
  );
};