import { FC, PropsWithChildren } from 'react';
import { Box, BoxProps, Typography } from '@mui/joy';

export type OrderProcessStepProps = PropsWithChildren & BoxProps;

export const OrderProcessStep: FC<OrderProcessStepProps> = ({ children, sx, ...props }) => (
  <Box
    sx={[
      ({ palette }) => ({
        height: 64,
        width: 64,
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        justifyContent: 'center',
        borderRadius: '50%',
        borderStyle: 'solid',
        borderWidth: 1,
        borderColor: palette.common[300],
        position: 'relative',
      }),
      ...(Array.isArray(sx) ? sx : [sx])
    ]}
    {...props}>
    <Typography
      sx={({ fontSize, palette }) => ({
        fontFamily: 'Michroma',
        fontSize: fontSize.lg,
        fontWeight: 400,
        lineHeight: '25.59px',
        textAlign: 'center',
        borderColor: palette.common.white
      })}>
      {children}
    </Typography>
  </Box>
);