import { FC, ReactNode } from 'react';
import { Stack, Typography } from '@mui/joy';
import { MintButton } from '@features/mint';
import { HeroImg } from './hero-img.component';

export type HeroProps = {
  title: ReactNode | string;
  subTitle: ReactNode | string;
};

export const Hero: FC<HeroProps> = ({ title, subTitle }) => (
  <Stack
    direction='column'
    alignItems='center'
    justifyContent='flex-start'
    sx={({ breakpoints, palette }) => ({
      background: palette.common[100],
      height: 723,
      paddingInline: 2,
      paddingBlockStart: 19.5,
      [breakpoints.down(1335)]: { height: 793 },
      [breakpoints.down(935)]: { height: 823 },
      [breakpoints.down(768)]: {
        paddingBlockStart: 6,
        height: 789
      },
      [breakpoints.down(655)]: { height: 754 },
      [breakpoints.down(559)]: { height: 712 },
      [breakpoints.down(521)]: { height: 612 }
    })}>
    <HeroImg />
    <Stack
      direction='column'
      sx={({ breakpoints }) => ({
        width: '100%',
        maxWidth: 1200,
        rowGap: 7,
        [breakpoints.down(768)]: { rowGap: 4 }
      })}>
      <Stack
        direction='column'
        sx={({ breakpoints }) => ({
          rowGap: 3,
          width: 722,
          [breakpoints.down(768)]: {
            rowGap: 2,
            width: '100%',
          }
        })}>
        <Typography
          sx={({ breakpoints, fontSize, palette }) => ({
            fontFamily: 'Michroma',
            fontSize: fontSize.xl4,
            fontWeight: 400,
            lineHeight: '91px',
            textAlign: 'left',
            color: palette.common.white,
            [breakpoints.down(768)]: {
              fontSize: fontSize.xl2,
              lineHeight: '45.5px',
            }
          })}>
          {title}
        </Typography>
        <Typography
          sx={({ breakpoints, fontSize, palette }) => ({
            fontFamily: 'Poppins',
            fontSize: fontSize.md,
            fontWeight: 300,
            lineHeight: '24px',
            textAlign: 'left',
            color: palette.common[400],
            [breakpoints.down(768)]: {
              fontSize: 14,
              lineHeight: '21px',
            }
          })}>
          {subTitle}
        </Typography>
      </Stack>
      <MintButton />
    </Stack>
  </Stack>
);