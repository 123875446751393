import { FC, Fragment, useState } from 'react';
import {
  IconButton,
  IconButtonProps,
  Drawer,
  Stack,
} from '@mui/joy';
import {
  ConnectWalletButton
} from '@features/connect-wallet';
import {
  Navbar,
  SocialLinks,
  Logo
} from '@shared/ui';
import {
  navigationData,
  socialLinkData
} from '@shared/config';
import {
  MenuIcon,
  CloseIcon
} from '@shared/icons';

export type MobileMenuProps = {};

export const MobileMenu: FC<IconButtonProps> = ({ sx, ...props }) => {
  const [open, setOpen] = useState<boolean>(false);

  const handleOpen = (): void => {
    setOpen(true);
  };

  const handleClose = (): void => {
    setOpen(false);
  };

  return (
    <Fragment>
      <IconButton
        onClick={handleOpen}
        sx={[
          ({ breakpoints }) => ({
            [breakpoints.up(796)]: { display: 'none' }
          }),
          ...(Array.isArray(sx) ? sx : [sx])
        ]}>
        <MenuIcon
          sx={({ palette }) => ({
            height: 24,
            width: 24,
            color: palette.common.white
          })}
        />
      </IconButton>
      <Drawer
        open={open}
        onClose={handleClose}
        sx={{ zIndex: 25 }}
        slotProps={{
          content: { sx: { width: '100%' } }
        }}>
        <Stack
          direction='row'
          alignItems='center'
          justifyContent='space-between'
          sx={{
            height: '8.625rem !important',
            padding: '2rem !important',
          }}>
          <Logo />
          <IconButton onClick={handleClose}>
            <CloseIcon
              sx={({ palette }) => ({
                height: 24,
                width: 24,
                color: palette.common.white
              })}
            />
          </IconButton>
        </Stack>
        <Stack
          direction='column'
          alignItems='center'
          justifyContent='space-between'
          sx={{
            height: '100%',
            paddingBlockStart: '11.75rem !important',
            paddingBlockEnd: '7rem !important'
          }}>
          <Stack
            direction='column'
            alignItems='center'
            justifyContent='flex-start'
            sx={{ rowGap: '11rem !important' }}>
            <Navbar
              vertical
              items={navigationData}
              onClick={handleClose}
              sx={({ fontSize }) => ({
                '.MuiLink-root': {
                  fontFamily: 'Poppins',
                  fontSize: fontSize.md,
                  fontWeight: 300,
                  lineHeight: '24px',
                  textAlign: 'left',
                }
              })}
            />
            <SocialLinks {...socialLinkData} />
          </Stack>
          <ConnectWalletButton sizeVariant='big' />
        </Stack>
      </Drawer>
    </Fragment>
  );
};