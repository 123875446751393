import { FC } from 'react';
import { Stack, Typography } from '@mui/joy';
import { useBlockchain } from '@entities/blockchain';
import { WhyUsStatsCard } from './why-us-stats-card.component';
import { WhyUsStatsSeparator } from './why-us-stats-separator.component';

export type WhyUsProps = {
  cardsContent: Array<{
    title: string;
    subTitle: string;
    imgSrc?: string;
  }>;
};

export const Dashboard: FC<WhyUsProps> = ({ cardsContent }) => {
  const { invitedByUser, invitedByFriends, earnByUser, balance } = useBlockchain();
  return (
    <Stack
      id='dashboard'
      component='div'
      direction='column'
      alignItems='center'
      justifyContent='center'
      sx={({ breakpoints, palette }) => ({
        background: palette.common[100],
        paddingInline: 2,
        paddingBlock: 13,
        [breakpoints.down(769)]: {
          paddingBlock: 8.25,
          paddingBlockEnd: 9
        }
      })}>
      <Stack
        direction='column'
        sx={({ breakpoints }) => ({
          width: '100%',
          maxWidth: 1200,
          rowGap: 7,
          [breakpoints.down(768)]: {
            rowGap: 4
          }
        })}>
        <Typography
          sx={({ fontSize, breakpoints, palette }) => ({
            fontFamily: 'Michroma',
            fontSize: fontSize.xl3,
            lineHeight: '56.88px',
            color: palette.common.white,
            fontWeight: 400,
            textAlign: 'start',
            [breakpoints.down(1024)]: {
              fontSize: fontSize.xl,
              lineHeight: '34.13px'
            }
          })}>
          Dashboard
        </Typography>
        
        <Stack
          direction='row'
          alignItems='center'
          justifyContent='space-between'
          sx={({ breakpoints }) => ({
            columnGap: 7,
            [breakpoints.down(796)]: {
              display: 'grid',
              gridTemplateColumns: '1fr 1fr',
              rowGap: 4
            },
            [breakpoints.down(420)]: {
              gridTemplateColumns: '1fr',
            }
          })}>
          <WhyUsStatsCard
            title={invitedByUser}
            subTitle='You Invited'
          />
          <WhyUsStatsSeparator />
          <WhyUsStatsCard
            title={(parseInt(invitedByFriends) - parseInt(invitedByUser)).toString()}
            subTitle='Your Friends Invited'
          />
          <WhyUsStatsSeparator />
          <WhyUsStatsCard
            title={earnByUser}
            subTitle='Total Earned'
          />
          <WhyUsStatsSeparator />
          <WhyUsStatsCard
            title={balance}
            subTitle='Current Balance'
          />
        </Stack>
      </Stack>
    </Stack>
  );
}