import { FC } from 'react';
import { SvgIcon, SvgIconProps } from '@mui/joy';

export const MenuIcon: FC<SvgIconProps> = (props) => (
  <SvgIcon
    width='22'
    height='14'
    viewBox='0 0 22 14'
    fill='none'
    xmlns='http://www.w3.org/2000/svg'
    {...props}>
    <path d='M1 1H21' stroke='white' strokeLinecap='round' />
    <path d='M1 7H21' stroke='white' strokeLinecap='round' />
    <path d='M1 13H21' stroke='white' strokeLinecap='round' />
  </SvgIcon>
);